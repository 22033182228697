import { 
    List, Datagrid, TextField, Show, SimpleShowLayout, 
    Edit, SimpleForm, SelectInput, TextInput,
    ReferenceInput, Create, DateField, DateTimeInput,
    useRecordContext, useDataProvider, Button,
    useNotify
} from 'react-admin';
import { envServiceFilters } from './filters';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';




const SetDeployTargetButton = (props) => {
    const record = useRecordContext(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    function setDeployTargetFromCandidate(record) {
        dataProvider.setDeployTargetFromCandidate(record)
            .then(() => notify(`Deploy Target set for ${record.env} ${record.service} to ${record.tag}`, {type: 'success'}))
            .catch(error => notify(`Error: Could not set Deploy Target for ${record.env} ${record.service} ` + error.message, 'warning'));
    }

    return (
        <Button label="Deploy!" size='large' variant='contained' onClick={() => setDeployTargetFromCandidate(record)}>
            <CloudUploadIcon />
        </Button>
    );
}


SetDeployTargetButton.defaultProps = { label: 'Promote this Candidate to Deploy Target', addLabel: true,}




export const DeployCandidateList = props => (
    <List {...props} filters={envServiceFilters} >
        <Datagrid rowClick="show">
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="tag" />
            <DateField source="created" showTime={true} locales='en-GB' views={['seconds']} />
            <TextField source="description" />
        </Datagrid>
    </List>
);


export const DeployCandidateShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="tag" />
            <DateField source="created" showTime={true} locales='en-GB' />
            <TextField source="description" />
            <SetDeployTargetButton />
        </SimpleShowLayout>
    </Show>
);


export const DeployCandidateEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <TextInput source="tag" />
            <DateTimeInput source="created" locales='en-GB' />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);


export const DeployCandidateCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <TextInput source="tag" />
            <DateTimeInput source="created" locales='en-GB' />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);
