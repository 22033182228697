import { 
    List, Datagrid, TextField, Button, useDataProvider, 
    useRecordContext, Show, SimpleShowLayout,
 } from 'react-admin';
import { ButtonGroup } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { envServiceFilters } from './filters';
import { useStyles } from './styles'

const DownloadField = (props) => {
    const record = useRecordContext(props);
    const dataProvider = useDataProvider();
    const classes = useStyles();
    return (
        <ButtonGroup>
            <Button className={classes.button} label=".env" variant='outlined' onClick={() => dataProvider.downloadEnvFile(record)}>
                <DownloadIcon />
            </Button>;
            <Button className={classes.button} label="env.json" variant='outlined' onClick={() => dataProvider.downloadEnvFile(record, true)}>
                <DownloadIcon />
            </Button>;
        </ButtonGroup>

    )
}

DownloadField.defaultProps = { label: '.Env File', addLabel: true,}


export const EnvFileList = props => (
    <List {...props} filters={envServiceFilters} >
        <Datagrid rowClick="show">
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="hash" />
            <DownloadField />
        </Datagrid>
    </List>
);


export const EnvFileShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="hash" />
            <DownloadField />
        </SimpleShowLayout>
    </Show>
);

