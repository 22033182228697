import { 
    List, Datagrid, TextField, Show, SimpleShowLayout, 
    Edit, SimpleForm, SelectInput, TextInput,
    ReferenceInput, Create
} from 'react-admin';
import { envServiceFilters } from './filters';

export const DeployTargetList = props => (
    <List {...props} filters={envServiceFilters} >
        <Datagrid rowClick="show">
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="tag" />
        </Datagrid>
    </List>
);

export const DeployTargetShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="env" />
            <TextField source="service" />
            <TextField source="tag" />
        </SimpleShowLayout>
    </Show>
);

export const DeployTargetEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <TextInput source="tag" />
        </SimpleForm>
    </Edit>
);

export const DeployTargetCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <TextInput source="tag" />
        </SimpleForm>
    </Create>
);