import { apiUrl } from './settings'


function getFailureMsg(body) {
    const reason = body.detail.error
    if (reason === 'invalid_grant') {
        return 'Login code already used, please log in again';
    } else {
        return `Login Failed: ${reason}`;
    };
}

export function isAuthorized() {
    const authItem = localStorage.getItem('auth');
    if (!authItem) {
        return false;
    };
    const auth = JSON.parse(authItem);
    if (!auth.id_token) {
        return false;
    };

    return true;
}


const authProvider = {
  login: (authorizationCode) =>  {
    const url = `${apiUrl}auth/credentials`;
    const request = new Request(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({code: authorizationCode}),
    });
  
    return fetch(request)
    .then(response => {
          if (response.ok) {
              return response.json();
            }
            
            return response.json().then(body => {
                const msg = getFailureMsg(body)
                return Promise.reject(msg)
            });
        })
        .then(auth => {
          localStorage.setItem('auth', JSON.stringify(auth));
          return Promise.resolve();
        })
        .catch(error => {
          const msg = `Internal Error: ${error}`
          return Promise.reject(msg);
    });
  },
  checkAuth: () => isAuthorized() 
      ? Promise.resolve() 
      : Promise.reject({ message: 'Login Required -> Redirecting to Login Page' }),
  logout: () => {
      localStorage.removeItem('auth');
      return Promise.resolve();
  },
  getPermissions: () => {
      // Required for the authentication to work
      return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
        localStorage.removeItem('auth');
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  // ...
};

export default authProvider;
