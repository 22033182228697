// in src/MyLoginPage.js
import * as React from 'react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { apiUrl } from '../settings';
import { isAuthorized } from '../authProvider';

function notifyLoginAgain(msg, notify) {
    const advice = 'Redirecting to Login Page.'
    notify(msg + '. ' + advice);
}

function redirectToLogin() {
    window.location.replace(`${apiUrl}login_page`);
}

const Login = (props) => {

    const login = useLogin();
    const notify = useNotify();
    
    const qs = queryString.parse(props.location.search)
    
    useEffect(() => {
        localStorage.removeItem('auth');
        const authorizationCode = qs.code
        
        if ( !authorizationCode ) {
            redirectToLogin();
            return;
        }
        
        login(authorizationCode).then(() => {
            notify('Login Successful');
            return;
        })
        .catch(msg => {
            redirectToLogin();
            return;
        });
    }, [qs.code]);

    return <Notification />
};

export default Login;