import { 
    List, Datagrid, TextField, SimpleShowLayout, 
    Edit, SimpleForm, SelectInput, TextInput,
    ReferenceInput, Create, BooleanField, BooleanInput,
    useRecordContext, Button, useDataProvider, 
    ShowController, ShowView,
} from 'react-admin';
import { useState } from 'react';
import { envServiceNameFilters } from './filters';
import { useStyles } from './styles'
import { resources } from '../settings'


const RevealButton = (props) => {
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const record = useRecordContext(props);

    function reveal(record) {
        const params = {id: record.id, reveal: true}
        dataProvider.getOne(resources.env_vars, params).then(
            (envVar) => {
                props.setRelevantRecord(envVar.data);
                props.setRevealed(true);
            }
        );
    };

    return <Button 
        className={classes.button} 
        label="Show Secret" 
        variant='outlined' 
        onClick={() => reveal(record)}
    />

}

export const EnvVarList = props => (
    <List {...props} filters={envServiceNameFilters} >
        <Datagrid rowClick="show">
            <TextField source="env" />
            <TextField source="service" />
            <BooleanField source="is_secret" label="Secret" />
            <TextField source="name" />
            <TextField source="value" style={{overflowWrap: 'anywhere'}} />
        </Datagrid>
    </List>
);

export const EnvVarShow = props => {
    const record = useRecordContext(props);
    const [revealed, setRevealed] = useState(false);
    const [relevantRecord, setRelevantRecord] = useState(record);

    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps}>
                    <SimpleShowLayout>
                        <TextField source="env" />
                        <TextField source="service" />
                        <BooleanField source="is_secret" label="Secret" />
                        <TextField source="name" />
                        <TextField 
                            record={relevantRecord}
                            source="value" 
                            style={{overflowWrap: 'anywhere'}} 
                        />
                        { 
                            controllerProps.record && controllerProps.record.is_secret && !revealed &&
                            <RevealButton 
                                setRevealed={setRevealed}
                                setRelevantRecord={setRelevantRecord}
                            />
                        }
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    );
};

export const EnvVarEdit = props => (
    <Edit mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <BooleanInput source="is_secret" label="Secret" />
            <TextInput source="name" />
            <TextInput source="value" multiline={ true } />
        </SimpleForm>
    </Edit>
);

export const EnvVarCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Env" source="env" reference="envs">
                <SelectInput source="env" />
            </ReferenceInput>
            <ReferenceInput label="Service" source="service" reference="services">
                <SelectInput source="service" />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="value" />
        </SimpleForm>
    </Create>
);