import * as React from "react";
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { DeployTargetList, DeployTargetShow, DeployTargetEdit, DeployTargetCreate } from "./modules/deployTargets";
import { DeployCandidateList, DeployCandidateShow, DeployCandidateEdit, DeployCandidateCreate } from "./modules/deployCandidates";
import { EnvVarList, EnvVarShow, EnvVarEdit, EnvVarCreate } from "./modules/envVars";
import { EnvFileList, EnvFileShow } from "./modules/envFiles";
import customRoutes from './customRoutes';
import Login from "./modules/loginPage"
import { createBrowserHistory as createHistory } from 'history';
import { resources } from './settings';

const history = createHistory();

const App = () => (
    <Admin 
        authProvider={authProvider}
        dataProvider={dataProvider} 
        loginPage={Login}
        customRoutes={customRoutes}
        history={history}
    >
        <Resource name={resources.deploy_targets} list={DeployTargetList} show={DeployTargetShow} />
        <Resource name={resources.deploy_candidates} list={DeployCandidateList} show={DeployCandidateShow} edit={DeployCandidateEdit} create={DeployCandidateCreate} />
        <Resource name={resources.env_files} list={EnvFileList} show={EnvFileShow} />
        <Resource name={resources.env_vars} list={EnvVarList} show={EnvVarShow} edit={EnvVarEdit} create={EnvVarCreate} />
        <Resource name={resources.envs} />
        <Resource name={resources.services} />
    </Admin>

);

export default App;