import { TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { resources } from '../settings';

export const envServiceFilters = [
    <ReferenceInput label="Env" source="env" reference={resources.envs} alwaysOn ><SelectInput source="env" /></ReferenceInput>,
    <ReferenceInput label="Service" source="service" reference={resources.services} alwaysOn ><SelectInput source="service" /></ReferenceInput>,
];

export const envServiceNameFilters = envServiceFilters.concat([
    <TextInput label="Name" source="name" alwaysOn />,
]);