

const backendLookup = {
    'development': 'http://localhost:8000/',
    // 'development': 'https://api.deploy.route.optimiser.app/',
    'test': 'http://localhost:8000/',
    'production': 'https://api.deploy.route.optimiser.app/',
}

export const apiUrl = backendLookup[process.env.NODE_ENV]

export const resources = {
    envs: 'environments',
    services: 'services',
    env_vars: 'env/vars',
    env_files: 'env/files',
    file_hashes: 'env/files/hashes',
    deploy_candidates: 'deploy/candidates',
    deploy_targets: 'deploy/targets',
}