import { fetchUtils } from 'react-admin';
import { apiUrl, resources } from './settings'


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    const saved = localStorage.getItem('auth');
    if (saved === null) {
        return Promise.reject();
    }
    const auth = JSON.parse(saved);
    const token = auth.access_token
    if (token === null) {
        return Promise.reject();
    }

    options.headers.set('Authorization', token);
    
    return fetchUtils.fetchJson(url, options);
};


function paramsToQuery(params) {
    const filter = params.filter
    const queryParts = [
        filter.env ? `envs=${filter.env}` : null,
        filter.service ? `services=${filter.service}` : null,
        filter.name ? `name=${filter.name}` : null,
    ]
    return queryParts.filter(part => part).join('&');
};


function addIDToObject(resource, object) {
    if (resource === resources.envs) {
        return { ...object, id: object.name };
    } else if (resource === resources.services) {
        return { ...object, id: object.name };
    } else if (resource === resources.deploy_targets) {
        return { ...object, id: `${object.env}#${object.service}` };
    } else if (resource === resources.deploy_candidates) {
        return { ...object, id: `${object.env}#${object.service}#${object.created}` };
    } else if (resource === resources.env_vars) {
        return { ...object, id: `${object.env}#${object.service}#${object.name}` };
    } else if (resource === resources.env_files) {
        return { ...object, id: `${object.env}#${object.service}` };
    } else {
        return object;
    };
}


function addIDToObjects(resource, objects) {
    return objects.map(object => addIDToObject(resource, object));
};


function getOneUrl(resource, params) {
    if (resource === resources.env_vars) {
        const [env, service, name] = params.id.split('#');
        const query = 'reveal' in params ? `?reveal=${params.reveal}` : ''
        return `${apiUrl}${resource}/${env}/${service}/${name}${query}`;
    } else if (resource === resources.env_files) {
        const [env, service] = params.id.split('#');
        return `${apiUrl}${resources.file_hashes}/${env}/${service}`;
    } else if (resource === resources.deploy_candidates) {
        const [env, service, created] = params.id.split('#');
        return `${apiUrl}${resources.deploy_candidates}/${env}/${service}/${created}`;
    }
    const [env, service] = params.id.split('#');
    return `${apiUrl}${resource}/${env}/${service}`;
};

const deleteUrl = getOneUrl

function getBaseListUrl(resource) {
    if (resource === resources.env_files) {
        return `${apiUrl}${resources.file_hashes}`;
    }
    return `${apiUrl}${resource}`;
}


function getListUrl(resource, params) {
    const url = getBaseListUrl(resource);
    const query = paramsToQuery(params);
    if (query) {
        return `${url}?${query}`;
    }
    return url;
}


function updateURL(resource) {
    return `${apiUrl}${resource}`;
}

const createURL = updateURL

const createMethod = {
    [resources.deploy_targets]: 'PUT',
    [resources.deploy_candidates]: 'PUT',
    [resources.env_vars]: 'PUT',
}

function downloadFile(body, filename, contentType) {
    const blob = new Blob([body], {type: contentType});
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
}

const requestTypes = {
    getList: (resource, params) => {
        const url = getListUrl(resource, params);
        return httpClient(url).then(({ headers, json }) => ({
            data: addIDToObjects(resource, json),
            total: parseInt(json.length, 10),
        }));
    },
    getOne: (resource, params) =>
        httpClient(getOneUrl(resource, params)).then(({ json }) => ({
            data: addIDToObject(resource, json),
        })),
    getMany: (resource, params) => {
        const url =`${apiUrl}${resource}`;
        return httpClient(url).then(({ headers, json }) => ({
            data: addIDToObjects(resource, json),
            total: parseInt(json.length, 10),
        }));
    },
    update: (resource, params) =>
        httpClient(updateURL(resource), {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: addIDToObject(resource, json) })),
    create: (resource, params) =>
        httpClient(createURL(resource), {
            method: createMethod[resource],
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: addIDToObject(resource, json),
        })),
    downloadEnvFile: (record, asJson=false) => {
        const contentType = asJson ? 'application/json' : 'text/plain';
        const filename = asJson ? `${record.env}_${record.service}_env.json` : `${record.env}_${record.service}.env`;
        const asJsonQuery = asJson ? '?as_json=true' : '';
        const url = `${apiUrl}${resources.env_files}/${record.env}/${record.service}${asJsonQuery}`;
        return httpClient(url, {
            method: 'GET'
        }).then(({ body }) => {
            downloadFile(body, filename, contentType);
            return {data: body};
        });
    },
    setDeployTargetFromCandidate: (record) => {
        const resource = resources.deploy_targets;
        const target = {
            env: record.env,
            service: record.service,
            tag: record.tag,
        }
        return httpClient(createURL(resource), {
            method: createMethod[resource],
            body: JSON.stringify(target),
        }).then(({ json }) => ({
            data: addIDToObject(resources.deploy_targets, json),
        }));
    },
    delete: (resource, params) => {
        httpClient(deleteUrl(resource, params), {
            method: 'DELETE',
        })
        .then((response) => ({ data: {response} }));
    },
    deleteMany: (resource, params) => {
        Promise.allSettled(params.ids.map(id => httpClient(deleteUrl(resource, {id}), {
            method: 'DELETE',
        }))).then(responses => {
            console.log('delete many then', responses);
            return { data: {}};
            // return Promise.resolve()
            // return { statusCodes: statusCodes };
        });
    },
    //     const query = {
    //         filter: JSON.stringify({ id: params.ids}),
    //     };
    //     return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    //         method: 'DELETE',
    //     }).then(({ json }) => ({ data: json }));
    // }  
}

export default requestTypes;



// export default {
//     getList: (resource, params) => {
//         // const { page, perPage } = params.pagination;
//         // const { field, order } = params.sort;
//         // const query = {
//         //     sort: JSON.stringify([field, order]),
//         //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
//         //     filter: JSON.stringify(params.filter),
//         // };
//         // const url = `${apiUrl}/${resource}?${stringify(query)}`;
//         const url = `${apiUrl}/${resource}`;

//         return httpClient(url).then(({ headers, json }) => ({
//             data: json,
//             // total: parseInt(headers.get('content-range').split('/').pop(), 10),
//         }));
//     },

    // getOne: (resource, params) =>
    //     httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
    //         data: json,
    //     })),

//     getMany: (resource, params) => {
//         const query = {
//             filter: JSON.stringify({ id: params.ids }),
//         };
//         const url = `${apiUrl}/${resource}?${stringify(query)}`;
//         return httpClient(url).then(({ json }) => ({ data: json }));
//     },

//     getManyReference: (resource, params) => {
//         const { page, perPage } = params.pagination;
//         const { field, order } = params.sort;
//         const query = {
//             sort: JSON.stringify([field, order]),
//             range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
//             filter: JSON.stringify({
//                 ...params.filter,
//                 [params.target]: params.id,
//             }),
//         };
//         const url = `${apiUrl}/${resource}?${stringify(query)}`;

//         return httpClient(url).then(({ headers, json }) => ({
//             data: json,
//             total: parseInt(headers.get('content-range').split('/').pop(), 10),
//         }));
//     },

//     update: (resource, params) =>
//         httpClient(`${apiUrl}/${resource}/${params.id}`, {
//             method: 'PUT',
//             body: JSON.stringify(params.data),
//         }).then(({ json }) => ({ data: json })),

//     updateMany: (resource, params) => {
//         const query = {
//             filter: JSON.stringify({ id: params.ids}),
//         };
//         return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
//             method: 'PUT',
//             body: JSON.stringify(params.data),
//         }).then(({ json }) => ({ data: json }));
//     },

//     create: (resource, params) =>
//         httpClient(`${apiUrl}/${resource}`, {
//             method: 'POST',
//             body: JSON.stringify(params.data),
//         }).then(({ json }) => ({
//             data: { ...params.data, id: json.id },
//         })),

//     delete: (resource, params) =>
//         httpClient(`${apiUrl}/${resource}/${params.id}`, {
//             method: 'DELETE',
//         }).then(({ json }) => ({ data: json })),

//     deleteMany: (resource, params) => {
//         const query = {
//             filter: JSON.stringify({ id: params.ids}),
//         };
//         return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
//             method: 'DELETE',
//         }).then(({ json }) => ({ data: json }));
//     }
// };